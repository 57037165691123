import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider, signInWithPopup, createUserWithEmailAndPassword } from '../firebase/firebase';
import { Button, Container, TextField, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next'; 

function SignUp() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(''); 

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    async function handleEmailSignUp(event) {
        event.preventDefault();
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error signing up with email/password: ', error);
            let message = '';
            switch (error.code) {
                case 'auth/email-already-in-use':
                    message = t('signUp.errors.emailAlreadyInUse');
                    break;
                case 'auth/invalid-email':
                    message = t('signUp.errors.invalidEmail');
                    break;
                case 'auth/weak-password':
                    message = t('signUp.errors.weakPassword');
                    break;
                default:
                    message = t('signUp.errors.generalError');
            }
            setErrorMessage(message);
        }
    }
    async function handleGoogleSignUp() {
        try {
            await signInWithPopup(auth, googleProvider);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error signing up with Google: ', error);
            // Display error message to the user
        }
    }

    return ( 
        <Box paddingTop={4}>
    <Container maxWidth = "xs" >
        <Typography variant = "h4"
        align = "center"gutterBottom >
        {t('signUp.title')}</Typography> {/* Update this line */}
        <form onSubmit = { handleEmailSignUp } >
        <TextField fullWidth margin = "normal"
        label = {t('signUp.emailLabel')}
        type = "email"
        value = { email }
        onChange = { handleEmailChange }
        required />
        <TextField fullWidth margin = "normal"
        label = {t('signUp.passwordLabel')}
        type = "password"
        value = { password }
        onChange = { handlePasswordChange }
        required />
        <Button type = "submit"
        fullWidth variant = "contained"
        color = "primary"
        sx = {
            { mt: 2 }
        } >
        {t('signUp.signUpWithEmail')}</Button> {/* Update this line */}
        </form > <Button fullWidth variant = "outlined"
        color = "secondary"
        onClick = { handleGoogleSignUp }
        sx = {
            { mt: 2 }
        } >
        {t('signUp.signUpWithGoogle')}</Button> {/* Update this line */}
        <Typography color="error" align="center" sx={{ mt: 2 }}>
            {errorMessage}
        </Typography>
        </Container></Box>
        
    );
}

export default SignUp;