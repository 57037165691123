import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: '#7FDB79',
            onMain: '#003908',
            container: '#005310',
            onContainer: '#9AF892',
        },
        secondary: {
            main: '#95D784',
            onMain: '#003A01',
            container: '#14520F',
            onContainer: '#B0F49E',
        },
        tertiary: {
            main: '#A0CFD4',
            onMain: '#00363B',
            container: '#1E4D52',
            onContainer: '#BCEBF0',
        },
        error: {
            main: '#FFB4AB',
            onMain: '#690005',
            container: '#93000A',
            onContainer: '#FFDAD6',
        },
        background: {
            main: '#1A1C19',
            onMain: '#E2E3DD',
        },
        surface: {
            main: '#1A1C19',
            onMain: '#E2E3DD',
            variant: '#424940',
            onVariant: '#C2C8BD',
        },
        outline: {
            main: '#8C9388',
        },
        inverse: {
            surface: '#E2E3DD',
            onSurface: '#1A1C19',
            primary: '#056E1A',
        },
        shadow: {
            main: '#000000',
        },
        tint: {
            surface: '#7FDB79',
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
    },
});

export default theme;