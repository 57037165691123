import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const HeroSection = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80vh',
  backgroundColor: theme.palette.background.main,
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '4rem',
  textAlign: 'center',

  color: theme.palette.surface.onMain,
  marginBottom: '2rem',
}));

const Subheading = styled(Typography)(({ theme }) => ({
  fontWeight: 'normal',
  fontSize: '2rem',
  color: theme.palette.secondary.main,
  marginBottom: '4rem',
}));

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const CTAButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.onMain,
  borderRadius: '2rem',
  padding: '1rem 2rem',
  fontSize: '1.5rem',
  '&:hover': {
    backgroundColor: theme.palette.primary.container,
  },
}));

const FeatureSection = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.surface.variant,
  color: theme.palette.background.main,
  padding: '4rem',
}));

const FeatureHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '2rem',
  color: theme.palette.background.onMain,

  marginBottom: '2rem',
}));

const FeatureDescription = styled(Typography)(({ theme }) => ({
  fontWeight: 'normal',
  fontSize: '1.5rem',
  color: theme.palette.background.onMain,

  marginBottom: '4rem',
}));

const NewLandingPage = () => {
  const theme = useTheme();

  return (
    <>
      <HeroSection theme={theme}>
        <div>
          <Heading theme={theme}>Get Better at Algorithms</Heading>
          <Subheading theme={theme}>Master complex algorithms in a fraction of the time with our personalized learning platform.</Subheading>
          <ButtonContainer>
            <CTAButton theme={theme}>Get Started</CTAButton>
          </ButtonContainer>
        </div>
      </HeroSection>
      <FeatureSection theme={theme}>
        <div>
          <FeatureHeading theme={theme}>Personalized Learning Paths</FeatureHeading>
          <FeatureDescription theme={theme}>Our platform provides customized learning paths based on your skill level and learning style, so you can focus on what you need to improve.</FeatureDescription>
          <FeatureHeading theme={theme}>Practice Exercises</FeatureHeading>
          <FeatureDescription theme={theme}>Our library of practice exercises and challenges is designed to help you master complex algorithms step-by-step.</FeatureDescription>
          <FeatureHeading theme={theme}>Performance Tracking</FeatureHeading>
          <FeatureDescription theme={theme}>Our performance tracking system helps you monitor your progress and identify areas for improvement, so you can reach your coding goals faster.</FeatureDescription>
        </div>
      </FeatureSection>
    </>
  );
};

export default NewLandingPage;
