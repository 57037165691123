import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  Button,
  
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function InstagramForm({ isLoggedIn }) {
  const { t } = useTranslation();
  const steps = t('instagramForm.steps', { returnObjects: true });
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [postURL, setPostURL] = useState('');
  const [numMentions, setNumMentions] = useState('');
  const [numWinners, setNumWinners] = useState('');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFinishClick = () => {
    if (isLoggedIn) {
      handleNext();
    } else {
      navigate('/sign-up'); // Assuming you have a separate sign-up page
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <TextField label={t('instagramForm.instagramPostURL')} fullWidth value={postURL} onChange={(e) => setPostURL(e.target.value)} />;
      case 1:
        return <TextField label={t('instagramForm.numberOfMentions')} fullWidth type="number" value={numMentions} onChange={(e) => setNumMentions(e.target.value)} />;
      case 2:
        return <TextField label={t('instagramForm.numberOfWinners')} fullWidth type="number" value={numWinners} onChange={(e) => setNumWinners(e.target.value)} />;
      case 3:
        return <Typography variant="body1">{t('instagramForm.clickToSelectWinners')}</Typography>;
      default:
        return 'Unknown step';
    }
  };

  return (
    <div>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography variant="h6">Winners Selected</Typography>
            {/* Display the winners here */}
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            <Button disabled={activeStep === 0} onClick={handleBack}>
              {t('instagramForm.back')}
            </Button>
            <Button
              variant="contained"
              onClick={handleFinishClick}
              disabled={!isLoggedIn && activeStep === steps.length - 2}
            >
              {activeStep === steps.length - 2 ? t('instagramForm.finish') : t('instagramForm.next')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default InstagramForm;
