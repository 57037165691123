// ResponsiveTypography.js
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
    // Mobile-first styles
    fontSize: 16,

    // Styles for larger screens
    [theme.breakpoints.up('sm')]: {
        fontSize: 18,
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 20,
    },
}));

export default ResponsiveTypography;