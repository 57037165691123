// ResponsiveHeading.js
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const ResponsiveHeading = styled(Typography)(({ theme }) => ({
    // Mobile-first styles
    fontSize: 18,
    marginTop: theme.spacing(3), // Add this line for margin-top


    // Styles for larger screens
    [theme.breakpoints.up('sm')]: {
        fontSize: 24,
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 30,
    },
}));

export default ResponsiveHeading;