import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider, signInWithPopup, signInWithEmailAndPassword } from '../firebase/firebase';
import { Button, Container, TextField, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function SignIn() {
    const { t } = useTranslation(); 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    async function handleEmailSignIn(event) {
        event.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error signing in with email/password: ', error);
            let message = '';
            switch (error.code) {
                case 'auth/user-not-found':
                    message = t('signIn.errors.userNotFound');
                    break;
                case 'auth/invalid-email':
                    message = t('signIn.errors.invalidEmail');
                    break;
                case 'auth/wrong-password':
                    message = t('signIn.errors.wrongPassword');
                    break;
                default:
                    message = t('signIn.errors.generalError');
            }
            setErrorMessage(message);
        }
    }

    async function handleGoogleSignIn() {
        try {
            await signInWithPopup(auth, googleProvider);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error signing in with Google: ', error);
            // Display error message to the user
        }
    }


    // Your existing handleGoogleSignIn function

    return ( 
        <Box paddingTop={4}>
        <Container maxWidth = "xs" >
        <Typography variant = "h4"
        align = "center"
        gutterBottom >
        {t('signIn.title')}</Typography> {/* Update this line */}
        <form onSubmit = { handleEmailSignIn } > 
        <TextField fullWidth margin = "normal"
        label = {t('signIn.emailLabel')} 
        type = "email"
        value = { email }
        onChange = { handleEmailChange }
        required/>
        <TextField fullWidth margin = "normal"
        label = {t('signIn.passwordLabel')}
        type = "password"
        value = { password }
        onChange = { handlePasswordChange }
        required/>
        <Button type = "submit"
        fullWidth variant = "contained"
        color = "primary"
        sx = {
            { mt: 2 }
        } >
        {t('signIn.signInWithEmail')}</Button> {/* Update this line */}
        </form> 
        <Button fullWidth variant = "outlined"
        color = "secondary"
        onClick = { handleGoogleSignIn }
        sx = {
            { mt: 2 }
        } >
        {t('signIn.signInWithGoogle')}</Button> {/* Update this line */}
        <Typography color="error" align="center" sx={{ mt: 2 }}>
            {errorMessage}
        </Typography>
        </Container >
        </Box>
    );
    
}

export default SignIn;