import React, { useState } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../../firebase/firebase.js';
import { signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: 'white',
  textDecoration: 'none',
  marginRight: theme.spacing(2),

  '&:last-child': {
    marginRight: 0,
  },

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

function NavBar() {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { auth, currentUser } = useAuth();
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log('User signed out');
        navigate('/');
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  const menuItems = [
    currentUser
      ? { text: t('navBar.menu.dashboard'), path: '/dashboard' }
      : { text: t('navBar.menu.home'), path: '/' },
    !currentUser && { text: t('navBar.menu.signIn'), path: '/sign-in' },
    !currentUser && { text: t('navBar.menu.signUp'), path: '/sign-up' },
    currentUser && { text: t('navBar.menu.myAccount'), path: '/my-account' },
    currentUser && { text: t('navBar.menu.signOut'), onClick: handleSignOut },
  ].filter(Boolean);

  const drawerList = (
    <List>
      {menuItems.map((item, index) => (
        <ListItem
          key={index}
          button
          component={item.path ? Link : 'button'}
          to={item.path}
          onClick={item.onClick || toggleDrawer(false)}
        >
          <Typography variant="subtitle1">{item.text}</Typography>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <AppBar position="static">
        <StyledToolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            {menuItems.map((item, index) => (
              <StyledLink key={index} to={item.path} onClick={item.onClick}>
                {item.text}
              </StyledLink>
            ))}
          </Typography>
        </StyledToolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList}
      </Drawer>
    </>
  );
}

export default NavBar;