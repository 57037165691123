import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme.js';
import reportWebVitals from './reportWebVitals';

import App from './App';
import { AuthProvider } from './firebase/firebase'; // Import the AuthProvider
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n.js';
import './i18n';

ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}> {/* Wrap your App with I18nextProvider */}
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ThemeProvider>
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
  
  reportWebVitals();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals