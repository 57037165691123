import React from 'react';

function TermsOfService() {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p>By accessing this website or app, you are agreeing to be bound by these website Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>

      <h2>Disclaimer</h2>
      <p>The materials on this website are provided "as is". The website makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, the website does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p>

      <h2>Limitations</h2>
      <p>In no event shall the website or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the website, even if the website or a website authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>

      <h2>Accuracy of materials</h2>
      <p>The materials appearing on the website could include technical, typographical, or photographic errors. The website does not warrant that any of the materials on its website are accurate, complete, or current. The website may make changes to the materials contained on its website at any time without notice. The website does not, however, make any commitment to update the materials.</p>

      <h2>Links</h2>
      <p>The website has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by the website of the site. Use of any such linked website is at the user's own risk.</p>

      <h2>Modifications</h2>
      <p>The website may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>

      <h2>Governing Law</h2>
      <p>These terms and conditions are governed by and construed in accordance with the laws of Brazil and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>

  
    </div>
  );
}

export default TermsOfService;
