import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme.js';
import NavBar from './components/navigation/NavBar';
import Dashboard from './components/Dashboard';
import OldLandingPage from './components/OldLandingPage.js';
import NewLandingPage from './components/NewerLandingPage.js';
import MyAccount from './components/MyAccount';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';

function App() {
  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
       <NavBar />
      <Routes>
        <Route path="/" element={<NewLandingPage/>} />
        <Route path="/old-landing" element={<OldLandingPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </Router>
    </ThemeProvider>
  );
}


export default App;