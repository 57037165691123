import React from 'react';

function PrivacyPolicy() {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>This Privacy Policy governs the manner in which our app collects, uses, maintains, and discloses information collected from users (each, a "User") of the Simple Sorteio application.</p>
      <p>Personal identification information

</p>
      <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our App, register on the App, fill out a form, and in connection with other activities, services, features or resources we make available on our App. Users may be asked for, as appropriate, name, email address, phone number. Users may, however, visit our App anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain App-related activities.</p>
      <p>Non-personal identification information

</p>
      <p>We may collect non-personal identification information about Users whenever they interact with our App. Non-personal identification information may include the browser name, the type of computer and technical information about Users' means of connection to our App, such as the operating system and the Internet service providers utilized, and other similar information.</p>
      <p>How we use collected information</p>
      <p>Our App may collect and use Users' personal identification information for the following purposes:</p>
      <p>To improve customer service: Information you provide helps us respond to your customer service requests and support needs more efficiently.
To personalize user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our App.
To send periodic emails: We may use the email address to respond to their inquiries, questions, and/or other requests.</p>
      <p>How we protect your information</p>
      <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our App.</p>
      <p>Sharing your personal information

</p>
      <p>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>
      <p>Changes to this privacy policy

</p>
      <p>We have the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.

</p>
      <p>Your acceptance of these terms

</p>
      <p>By using this App, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our App. Your continued use of the App following the posting of changes to this policy will be deemed your acceptance of those changes.

</p>
      <p>This privacy policy was last updated on 3/17/23.



</p>
    
    </div>
  );
}

export default PrivacyPolicy;