import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebase';
import { createUserWithEmailAndPassword } from '../firebase/firebase';
import InstagramForm from './InstagramForm';
import {
    Container,
    Grid,
    TextField,
    Button,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next'; // Add this import

import ResponsiveHeading from '../common/ResponsiveHeading';
import ResponsiveTypography from '../common/ResponsiveTypography';

function OldLandingPage() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');


    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    function handleSignup(event) {
      event.preventDefault();
      createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
              console.log('User signed up with ID: ', userCredential.user.uid);
              navigate('/dashboard');
          })
          .catch((error) => {
              console.error('Error signing up: ', error);
              setErrorMessage(error.message);
          });
  }
  

    return (
      <Container maxWidth="md">
        <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item>
            <ResponsiveHeading variant="h2" component="h1" gutterBottom>
              {t('title')}
            </ResponsiveHeading>
          </Grid>
          <Grid item>
            <ResponsiveTypography variant="body1">
              {t('description')}
            </ResponsiveTypography>
          </Grid>
          {/* ... other code */}
          <Grid item>
            <ResponsiveTypography variant="h4" component="h2" gutterBottom>
              {t('unlock')}
            </ResponsiveTypography>
          </Grid>
          <Grid item>
            <form onSubmit={handleSignup}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <TextField
                    label={t('email')}
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label={t('password')}
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </Grid>
                <Grid item>
                  <Button variant="contained" type="submit">
                    {t('signup')}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Typography color="error" align="center" sx={{ mt: 2 }}>
            {errorMessage}
            </Typography>
          </Grid>
          <Grid item>
            <InstagramForm isLoggedIn={false}/>
          </Grid>
        
        </Grid>
        
      </Container>
    );
  }
export default OldLandingPage;