import React, { useState } from 'react';
import { useAuth } from '../firebase/firebase';
import { updatePassword } from 'firebase/auth';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function MyAccount() {
  const { t } = useTranslation();
  const { currentUser, signOut } = useAuth();
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();

    try {
      await updatePassword(currentUser, password);
      setMessage(t('myAccount.passwordUpdated'));
    } catch (error) {
      setMessage(`${t('myAccount.updateError')} ${error.message}`);
    }
  };

  return (
    <Box paddingTop={4}>
      <Container maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          {t('myAccount.title')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('myAccount.email')}: {currentUser.email}
        </Typography>
        <form onSubmit={handleChangePassword}>
          <TextField
            label={t('myAccount.newPassword')}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" type="submit">
              {t('myAccount.updatePassword')}
            </Button>
          </Box>
        </form>
        {message && (
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {message}
          </Typography>
        )}
        <Box mt={4}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => signOut()}
          >
            {t('myAccount.signOut')}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default MyAccount;
