import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import InstagramForm from './InstagramForm';
import { useAuth } from '../firebase/firebase';
import { Container, Grid } from '@mui/material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MyResponsiveHeading = styled(Typography)(({ theme }) => ({
  // Mobile-first styles
  fontSize: 18,

  // Styles for larger screens
  [theme.breakpoints.up('sm')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 30,
  },
}));

function Dashboard() {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const handleFormSubmit = (formData) => {
    console.log('Form data:', formData);
  };

  return (
    <Box paddingTop={4}>
    <Container maxWidth="md">
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item>
          <MyResponsiveHeading
            variant="h1"
            align="center"
            gutterBottom
            color="primary"
          >
            {t('dashboard.welcome')}
          </MyResponsiveHeading>
        </Grid>
        <Grid item>
          <InstagramForm
            onSubmit={handleFormSubmit}
            isLoggedIn={currentUser !== null}
          />
        </Grid>
      </Grid>
    </Container>
    </Box>
  );

}

export default Dashboard;
